/* TAIWO ADETAYO CSS @taiwoadetayo@gmail.com */

body {
  font-family: 'Open Sans', sans-serif !important;
}
.strike{
  text-decoration: line-through
}

body{
  background: #f7f7f7;
}
.loginBG{
  background: #fff;
}

.appname {
  font-size: 18px;
  width: 184px;
  margin-top: 11px;
  font-weight: 600;
  margin-left: 0px;
  border-left: 1px solid #f5b7b747;
  padding-left: 25px;
  padding-top: 37px;
  line-height: 13px;
}

#combo-box-demo{
  font-size: 12px;
  padding: 8px 10px;
}

#combo-box-demo::placeholder{
  color: #ff0000;
  opacity:1;
}

.view_icon{
  background: #0751b1 !important;
  border: 1px solid #2e76d4 !important;
}

.mr-13{margin-right: 13px;}
.auth-logo-area{
  /* box-shadow: -1px -1px 20px 120px #fff; */
  background: #fff;
}
.page-lead-wrap{
  /* border-bottom: 1px solid #f6f5f5; */
  padding-bottom: 10px;
  box-shadow: 2px 4px 230px 20px #e2e0e0;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
}
.page-lead-wrap nav{
  font-size: 12px;
}
.page-lead-wrap nav a{
  text-decoration: none;
}
.logoutbtn.dropdown-item:hover {
  background: none !important;
}

a{
  color: #3266fd;
}
.bold-500{
  font-weight: 500;
}

.form-group{
  position: relative;
}

.input-icon{
  position: absolute;
  top: 32px;
  right: 5px;
  /* background: #fff; */
  padding: 10px;
  color: #998e8e;
}
.input-feedback{
  position: absolute;
  top: 2px;
  right: 28px;
  /* background: #fff; */
  padding: 10px;
  color: #998e8e;
  border-left: 1px solid #f2f2f2;
  height: 40px;
}
.login-side-wrap{
  width: 70%;
}
.form-aside{
  /* height: 100vh; */
  background: #f6f6f6;
  box-shadow: #2e2e2e14 1px 0px 5px 1px;
  position: relative;
}
.login-lead{
  margin-top:15%;
  font-size: 60px;
  font-weight: 800;
  /* font-size: 40px; */
}
.login-lead-text{
  font-size: 20px;
  font-weight: 600;
}
.btn-register {
  background-color: #fff !important;
  border: 1px solid #0556de !important;
  color: #0556de !important;
  font-weight: 500;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(270deg);
  cursor: pointer;
}

.arrow span {
  -webkit-animation: animate 5s infinite;
  animation: animate 5s infinite;
  border-bottom: 2px solid #0556de;
  border-right: 2px solid #0556de;
  display: block;
  height: 0.6vw;
  margin: -15px -14px;
  width: 0.6vw;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}


.invoice-record-row:nth-child(even) {background: #f6f6f6}
.invoice-record-row:nth-child(odd) {background: #fff}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}

.btn-label {
  /* font-size: 20px !important; */
  position: absolute;
  right: 22px;
}

.success-check{
  font-size: 150px;
  background: #f3f2f3;
  padding: 30px;
  border-radius: 50%;
  color: green;
}


.swal2-popup.swal2-modal.swal2-show{
  width: auto !important;
}
.swal2-popup.swal2-modal.swal2-icon-error.swal2-show, .swal2-popup.swal2-modal.swal2-icon-success.swal2-show{
  width: 32em !important;
}


.font-12{
  font-size: 12px !important;
}
.input-font{
  font-size: 12px !important;
  padding: 10px 10px;
  border-radius: 2px;
}

.css-1wuilmg-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 10px 4px 6px 10px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000000;
}

.MuiTextField-root{
    background: #fff;
    border: 1px solid #ced4da !important;
    border-radius: 3px !important;
    font-size: 12px !important;
}
.css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #ced4da !important;
}

.border-radius-sm{
  border-radius: 2px;
}
.btn-primary {
  background-color: #0556de !important;
  /* height: 40px; */
}
/* .btn-danger {
  height: 40px;
} */
.form-group span{
  font-size: 12px;
}
.input-overlay{
  padding: 0 8px;
  background: #f6f6f6;
  top: 10px;
  left: 8px;
  position: relative;
  font-size: 12px !important;
  font-weight: 500;
  border-radius: 5px;
}

.copyright-area{
  font-size: 12px;
  color:#4e4e4e;
  background:#f6f6f6;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom:20px;

  /* position: absolute; */
  bottom: 0;
  /* z-index: 1000; */
  left: 5%;
  right: 5%;
}

.swal2-styled.swal2-confirm {
  background-color: #1e3d98 !important;
}
small{
  font-size: 12px !important;
}
.bell-value{
  background: red;
  padding: 3PX 8PX;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  right: 15px;
  top: -15px;
}
.bell-icon{
  color:red;
  font-size:25px;
}
.dropdown-menu-light{
  color:#000 !important;
  /* left: -60px !important; */
  font-size: 14px;
}
.dropdown.mr-13.navbar.navbar-expand.navbar-light{
  width: 200px;
}
.user-icon-svg-topnav{
  margin-right: 5px;
  font-size: 22px;
  top: -3px;
  position: relative;
  fill:#333;
}
.dropdown-toggle.nav-link {
  text-align: right !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bell-wrap{
  top: 15px;
  position: relative;
}
.page-lead{
  font-weight: 600;
  font-size: 18px;
}

.swal2-timer-progress-bar {
  background: rgba(230, 42, 42, 0.532) !important;
}




/* side navigation desktop & mobile */
.side-nav-wrap{
  width: 230px;
  background: rgb(255, 255, 255);
  height: 100%;
  border-right: 1px solid rgba(216, 216, 216, 0.32);
  padding: 0px;
  position: fixed;
}
.side-nav-area ul{
  padding: 0;
}
.side-nav-area ul li{
  list-style: none;
  width: 100%;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 600;
  transition: all 1s ease;
  border-bottom: 1px solid #f2f2f247;
}
.side-nav-area ul li a, .side-nav-area ul li div {
  text-decoration: none;
  text-decoration: none;
  padding: 7px 15px;
  width: 100%;
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  transition: all 1s ease;
  text-transform: uppercase;
}
.side-nav-area ul li.active {
  background: #2e76d4 !important;
  color: #fff !important;
}
.side-nav-area ul li.active > a{
  color: #fff;
}
.side-nav-area ul li.active svg, .side-nav-area ul li svg{
  margin-right: 15px;
  fill: #fff;
  top: -3px;
  position: relative;
  height: 1.1rem;
}
.side-nav-area ul li svg{
  fill: #383838;
}
.side-nav-area ul li.active svg{
  fill: #fff;
}
.side-nav-area ul li a, .side-nav-area ul li div{
  text-decoration: none;
  text-decoration: none;
  padding: 14px 25px;
  width: 100%;
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  transition: all 1s ease;
  text-transform: uppercase;
}

/* animated hover */
.side-nav-area ul li a, .side-nav-area ul li div{
  transition: all 0.5s ease-in-out;
  position: relative;
}
.side-nav-area ul li a::before, .side-nav-area ul li div::before {
  content: attr(data-item);
  transition: 0.5s;
  background: rgb(7 38 199 / 5%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  overflow: hidden;
}
.side-nav-area ul li a:hover::before, .side-nav-area ul li div:hover::before {
  width: 100%;
  transition: all 0.5s ease-in-out;
}



        /* Makes the animation pause on hover */
.moving-text:hover{
  animation-play-state: paused;
}

/* The animation */
@keyframes marquee{
  0%{transform: translateX(100%);}
  100%{transform: translateX(-100%);}
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text{
      animation: marquee 65s linear infinite;
  }
}





.main-content-wrap{
  width: calc(100% - 230px);
  background: rgb(247, 247, 247);
  padding: 0px;
  margin-left: 230px;
}




.bolder{
  font-weight: 500;
}
.bold{
  font-weight: 400;

}
.info-txt{
  background: #fff2acb8;;
  padding: 2px 10px;
  font-size: 12px;
  border-left: 2px solid black;
  border-radius: 0 2px 2px 0;
}
.uicheckbox{
  width: 20px;
  height: 18px;
}
legend{ float: none; padding: inherit; }
.px-7 {
  padding-right: 7rem!important;
  padding-left: 7rem!important;
}
.input-currency{
  position: absolute;
  top: 35px;
  left: 14px;
  padding-right: 10px;
  border-right: 1px solid #ced4da;
  font-size: 12px;
}
input.form-control:disabled {
  /* background-color: #f2f2f2 !important; */
  opacity: 1;
  cursor: not-allowed;
  border: 1px solid #2f0a0a1c;
  /* box-shadow: -2px 2px 3px 1px #822a2a12; */
}
.fixed-top-nav{
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(216, 216, 216, 0.32);
  /* box-shadow: rgb(242 242 242) 2px 0px 2px 2px; */
  position: fixed;
  right: 0;
  left: 230px;
  z-index: 1000;
}
.inappname {
  font-size: 16px;
  padding-left: 17px;
  padding-top: 20px;
  color: #333;
  /* text-shadow: -1px 0px 1px #0000002e; */
}
.form-control:focus {
  transition: all .5s ease;
}
button:hover{
  /* box-shadow: -1px 8px 20px 0px #0000005c; */
  transition: all 1s ease;
  box-shadow: -1px 0px 3px 1px #0726c72b;
}
button{
  transition: all 1s ease;
}
.lead-txt{
  background: #2e76d4;
  color: #fff;
}
/* .lead-txt {
  background: #f3f3f3;
  color: #4c4b4b;
  border: 1px solid #e1e1e1;
} */
.disable-bg{
  background: #f2f2f2;
  cursor: not-allowed;
}
.page-name{
  background: red;
  padding: 4px 26px;
  border-radius: 0 8px 0 0;
}

.css-b62m3t-container {
  font-size: 12px !important;
  font-weight: 500;
}
.css-1s2u09g-control {
  border-radius: 1px !important;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:hover, .css-1s2u09g-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  transition: all .5s ease;
}


.topnav-list-right {
  padding-right: 10px;
  border-right: 1px solid #F2F2C5;
}
.topnav-list-left {
  padding-left: 10px;
}

.btn.bg-light {
  border: 1px solid #2c67b5 !important;
  color: #2c67b5;
}

.btn.bg-light:disabled {
  border: 1px solid #e9ecef !important;
}

.css-1pahdxg-control {
  border-color: #f2f2f2 !important;
  border-radius: 1px !important;
  box-shadow: none !important;
}
.css-26l3qy-menu{
  margin-top: 2px !important;
  z-index: 9999;
}
input[type="checkbox"]{
  width:17px;
  height: 17px;
  border-radius: 0;
}
input[type="checkbox"]:checked {
  animation: effect 250ms ease-in;
  transition: all .5s ease;
  box-shadow: -1px 0px 6px 0.25rem rgb(13 110 253 / 16%);

}
thead tr {
  font-size: 12px;
  font-weight: 400 !important;
  text-transform: uppercase;
}

thead th {
  border: 1px solid #007bc2 !important;
  font-weight: 500;
  background: #007bc2 !important;
}

.bank-account-item{
  background: #f2f2f225;
  padding: 0px 20px 15px;
  border: 1px solid #ced4da;
  position: relative;
}

.bank-account-item.active:has(.bankCheckbox:checked), .bank-account-item.active:has(.bankCheckbox2:checked){
  border: 2px solid #0075ff !important
}

.bank-checked{
  position: absolute;
  top: -10px;
  right: -7px;
  background: #0075ff;
  color: #fff;
  padding: 8px;
}
.bankCheckbox {
  position: absolute;
  bottom: -12px;
  right: -12px;
  border: 1px solid #0075ff;
  color: #fff;
  height: 30px !important;
  width: 28px !important;
}
.bankCheckbox2 {
  position: absolute;
  bottom: 1px;
  right: 43px;
  border: 1px solid #0075ff;
  color: #fff;
  height: 0px !important;
  width: 28px !important;
}
.bankCheckbox:checked, .bankCheckbox2:checked{
  background: #0075ff;
}

.bankCheckbox:checked + label::after {
  content: 'Bank Selected';
  color:#fff;
  position: absolute;
  right: 19px;
  bottom: -16px;
  background: #0075ff;
  color: #fff !important;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
}
.bankCheckbox:disabled + label::after {
  content: 'Cannot apply this currency';
  color: #000;
  position: absolute;
  right: 19px;
  bottom: -16px;
  border: 1px solid #ced4da;
  background: #fff;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
}
.bankCheckbox + label::after {
  content: 'Click to select';
  color: #000;
  position: absolute;
  right: 20px;
  bottom: 5px;
  background: #fff;
  padding: 5px 40px;
  font-size: 12px;
  border: 1px solid #ced4da;
  font-weight: 500;
  border-radius: 2px;
}
.bankCheckbox2:checked + label::after {
  content: 'Bank Selected';
  color:#fff;
  position: absolute;
  right: 22px;
  bottom: 1px;
  background: #0075ff;
  color: #fff !important;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
}
.bankCheckbox2 + label::after {
  content: 'Click to select';
  color: #000;
  position: absolute;
  right: 22px;
  bottom: 1px;
  background: #f0f0f0;
  padding: 7px 40px;
  font-size: 12px;
  border: 1px solid #f2f2f2;
  font-weight: 500;
  border-radius: 2px;
}
.savedraftbtn{
  background: #fff !important;
  border: 2px solid blue !important;
  color: blue !important;
  font-weight: 600 !important;
}
.mpr-0{
  margin-right: 0 !important;
  padding-right: 0 !important;
}




.info-alert{
  background: rgb(242 242 242 / 91%);
  border-left: 3px solid #007bc2;
  padding: 9px;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}

button:disabled {
  background: #b5b4b4 !important;
  border: 1px solid #f2f2f2 !important;
  color: #fff !important;
}
.btn-sm{
  font-size: 10px !important;
}
a.disabled {
  pointer-events: none;
  cursor: default;
  background: #b5b4b4 !important;
  border: 1px solid #f2f2f2 !important;
}

.form-switch .form-check-input {
  width: 2.5em;
}

.form-check-input:checked {
  background-color: #0556de;
  border-color: #0d6efd;
}
.form-control:focus {
  border-color: #2650ff00;
  box-shadow: 0 0 0 0.01rem rgb(0 148 242 / 33%);
}
tbody, td, tfoot, th, thead, tr {
  font-weight: 400;
  font-size: 12px;
}

.bank-area-scrolling {
  height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 5px;
  border-bottom: 1px solid #b9b8b8;
}

.mobileNav{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  right: 20%;
  width: 80%;
}

.mobile-nav-area ul li{
  list-style: none;
  padding: 10px 0px;

}
.mobile-nav-area ul li a{
  text-decoration: none;
  text-decoration: none;
  /* padding: 7px 15px; */
  width: 100%;
  display: block;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: all 1s ease;
}
.mobile-nav-area ul li a > span {
  padding: 7px 15px;
  display: block;
}
.mobile-nav-area ul li a.active {
  text-decoration: none;
  /* padding: 7px 15px; */
  background: #2e76d4 !important;
  width: 100%;
  display: block;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  /* box-shadow: 0px -1px 4px 0px #0726c73d; */
  transition: all 1s ease;
}
.mobile-nav-area ul li a.active .fa-usd{
  background: #0556de !important;
}
.mobile-nav-area ul li a:hover{
  background: rgb(7 38 199 / 5%);
  transition: all 1s ease;
  border-radius: 4px;
}
.mobile-nav-area ul{
  padding: 0;
}

/* .css-1s2u09g-control, .css-1s2u09g-control:focus {
  padding: 2px;
} */

/* XS screen media css here */
@media only screen and (max-width: 540px) {
  .loginBG, body {
      background: #fff;
  }
  .form-aside {
    height: auto;
    background: #fbfbfb;
    box-shadow: none;
    position: relative;
    width: 95%;
    margin: 0 auto;
    border-radius: 5px;
  }
  .login-form .input-overlay {
    padding: 0 8px;
    background: #fff;
    top: 10px;
    left: 8px;
    position: relative;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 5px;
    display: none;
  }
  .login-form .form-group{
    margin-bottom: 5px !important;
  }
  .curved-top{
    border-radius: 2px 2px 0 0 !important;
  }
  .curved-bottom{
    border-radius:  0 0 2px 2px !important;
  }
  .login-form .input-icon {
    top: 9px;
  }
  .copyright-area {
    font-size: 12px;
    color: #4e4e4e;
    background: transparent;
    font-weight: 500;
    margin-top: 90px;
    margin-bottom: 40px;
    position: inherit;
    bottom: 0;
    z-index: 1000;
    left: auto;
    right: auto;
  }


 .logo-name{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    position: relative;
    top: -30px;
  }
  .login-side-wrap {
    width: 95%;
  }
  .login-lead {
    margin-top: 5%;
    font-size: 38px;
    font-weight: 800;
    text-align: center;
  }

  .side-nav-wrap {
    width: 225px;
    background: rgb(255, 255, 255);
    height: 100%;
    border-right: 1px solid rgba(216, 216, 216, 0.32);
    padding: 0px;
    position: fixed;
    display: none;
  }
  .main-content-wrap {
    width: 100%;
    background: rgb(247, 247, 247);
    padding: 0px;
    margin-left: 0;
  }
  .fixed-top-nav {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1000;
  }
  .inappname {
    font-size: 18px;
    padding-left: 17px;
    padding-top: 15px;
    color: #606060;
    text-shadow: -1px 0px 1px #0000002e;
    width: 100px;
  }
  .sm-px-4 {
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
  }
  .lead-txt {
    background: #2e76d4;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .mt-sm{
    margin-top: 10px !important;
  }
  .input-feedback {
    top: 2px;
    background: #fff !important;
    height: 40px;
  }
  .sm-mt-1 {
      margin-top: 0.85rem!important;
  }

  .page-lead-wrap {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .breadcrumb{
    margin-top: 20px !important;
  }

  .bg-white .px-4 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
  }
  .swal2-popup {
    max-width: 80% !important;
  }
  .w-220{
    width: 200px;
  }
  .w-100{
    width:100px !important;
  }
  .w-60{
    width:60px !important;
    display: block;
  }
  .page-lead-wrap {
    display: none;
  }
}

/* SM screen media css here */
@media only screen and (min-width: 541px) and (max-width: 960px) {
  .loginBG, body {
      background: #fff;
  }
  .form-aside {
    height: auto;
    background: #fbfbfb;
    box-shadow: none;
    position: relative;
    width: 70%;
    margin: 0 auto;
    border-radius: 5px;
  }
  .copyright-area {
    font-size: 12px;
    color: #4e4e4e;
    background: transparent;
    font-weight: 500;
    margin-top: 90px;
    margin-bottom: 40px;
    position: inherit;
    bottom: 0;
    z-index: 1000;
    left: auto;
    right: auto;
  }
  .login-lead {
    margin-top: 15%;
  }
  .login-form .input-overlay {
    display: none;
  }
  .login-form .input-icon {
    top: 9px;
  }
  .login-form .form-group {
    margin-bottom: 5px !important;
  }
  .login-side-wrap {
    width: 75%;
  }
}





.stepper {
  display: flex;
  padding: 0;
  width: 100%;
  list-style: none;
  position: relative;
}
.stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 20px);
  background: #f4f4f4;
}
.stepper__item {
  flex: auto;
  /* padding: 10px 8px 10px 30px; */
  padding: 10px 15px 10px 24px;
  font-size: 14px;
  background: repeating-linear-gradient(-65deg, #fff, #fff 20px, #fcfcfc 20px, #fcfcfc 40px);
  margin: 0 0 0 0px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  align-items: center;
  display: flex;
  font-weight: 500;
}
.font-14, .notification-message{
  font-size: 14px;
}
.stepper__item.current {
  background: #075dcd;
  color: #fff;
}
.stepper__item.complete {
  background: #5096f2;
  color: #fff;
  /* background: repeating-linear-gradient(-65deg, #fcfcfc, #fcfcfc 20px, #f4faf7 20px, #f4faf7 40px); */
}
.stepper__item.terminate {
  background: #ee372c !important;
  color: #fff;
}
.stepper__item.success {
  background: green !important;
  color: #fff;
}


.stepper__item:first-child {
  /* padding: 20px; */
  /* -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%); */
}
.stepper__item:last-child {
  -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}


.easeout{
  animation: fill .4s ease-in-out .4s backwards, scale .3s ease-in-out .9s both
}

.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: #fff;
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none
  }

  50% {
    transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 10px green
  }
}

.dropdown-menu[data-bs-popper] {
  right: 0;
}